import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import { enhancedResumeApi } from '../store/enhancedResumeApi';

const store = configureStore({
  reducer: {
    user: userReducer,
    [enhancedResumeApi.reducerPath]: enhancedResumeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(enhancedResumeApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
