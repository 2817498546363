import React from 'react'

import { HnJobPostingSchemaOut } from '../store/resumeApi';
import JobTagBadges from './JobTagBadges';
import HideJobButton from './HideJobButton';
import SaveJobButton from './SaveJobButton';

type JobPostProps = {
    job: HnJobPostingSchemaOut;
};


const JobPost: React.FC<JobPostProps> = ({
    job,
}) => {

    function convertTimePosted(jobTime) {
        const date = new Date(jobTime * 1000).toISOString().split('T')[0];
        return date;
    }

    return (
        <div key={job.id} className="p-6 my-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-700 dark:border-gray-600">
            <div className="flex justify-between">
                <div className="pb-5">
                    <a href={"https://news.ycombinator.com/user?id=" + job.posted_by}>
                        <span className="text-xl underline text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-500">
                            {job.posted_by}
                        </span>
                    </a>
                </div>

                <div>
                    <div className=" pb-5 flex flex-row items-center">
                        <a href={"https://news.ycombinator.com/item?id=" + job.hn_id}>
                            <p className="text-lg underline text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-500">{convertTimePosted(job.time_posted)}</p>
                        </a>
                    </div>

                </div>
            </div>
            <div
                className="wrap-display-text text-xl max-w-full  dark:text-gray-200"
                dangerouslySetInnerHTML={{ __html: job.display_text }}
            />

            <div className="flex flex-wrap mt-5">
                <div className="w-full my-2">
                    <JobTagBadges tags={job.tags} />
                </div>
                <div className="w-full flex justify-end my-2 space-x-2">
                    <SaveJobButton job_id={job.id} initialSaveState={job.saved} />
                    <HideJobButton job_id={job.id} initialHideState={job.hidden} />
                </div>
            </div>


        </div>
    )
};

export default JobPost
