import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    resumeViewsResumePdfToText: build.mutation<
      ResumeViewsResumePdfToTextApiResponse,
      ResumeViewsResumePdfToTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resume/upload`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    resumeViewsSaveJob: build.mutation<
      ResumeViewsSaveJobApiResponse,
      ResumeViewsSaveJobApiArg
    >({
      query: (queryArg) => ({
        url: `/api/job/${queryArg.jobId}/save`,
        method: "PUT",
      }),
    }),
    resumeViewsUnsaveJob: build.mutation<
      ResumeViewsUnsaveJobApiResponse,
      ResumeViewsUnsaveJobApiArg
    >({
      query: (queryArg) => ({
        url: `/api/job/${queryArg.jobId}/unsave`,
        method: "PUT",
      }),
    }),
    resumeViewsHideJob: build.mutation<
      ResumeViewsHideJobApiResponse,
      ResumeViewsHideJobApiArg
    >({
      query: (queryArg) => ({
        url: `/api/job/${queryArg.jobId}/hide`,
        method: "PUT",
      }),
    }),
    resumeViewsUnhideJob: build.mutation<
      ResumeViewsUnhideJobApiResponse,
      ResumeViewsUnhideJobApiArg
    >({
      query: (queryArg) => ({
        url: `/api/job/${queryArg.jobId}/unhide`,
        method: "PUT",
      }),
    }),
    resumeViewsGetSavedJobs: build.query<
      ResumeViewsGetSavedJobsApiResponse,
      ResumeViewsGetSavedJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobs/saved`,
        params: {
          ascending: queryArg.ascending,
          page: queryArg.page,
          page_size: queryArg.pageSize,
        },
      }),
    }),
    resumeViewsGetHiddenJobs: build.query<
      ResumeViewsGetHiddenJobsApiResponse,
      ResumeViewsGetHiddenJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobs/hidden`,
        params: {
          ascending: queryArg.ascending,
          page: queryArg.page,
          page_size: queryArg.pageSize,
        },
      }),
    }),
    resumeViewsGetJobs: build.query<
      ResumeViewsGetJobsApiResponse,
      ResumeViewsGetJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobs`,
        params: {
          resume_uuid: queryArg.resumeUuid,
          month: queryArg.month,
          year: queryArg.year,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          tags: queryArg.tags,
          saved: queryArg.saved,
          hidden: queryArg.hidden,
        },
      }),
    }),
    resumeViewsCreateResume: build.mutation<
      ResumeViewsCreateResumeApiResponse,
      ResumeViewsCreateResumeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resume`,
        method: "POST",
        body: queryArg.createResumeIn,
      }),
    }),
    resumeViewsGetResume: build.query<
      ResumeViewsGetResumeApiResponse,
      ResumeViewsGetResumeApiArg
    >({
      query: (queryArg) => ({ url: `/api/resume/${queryArg.resumeUuid}` }),
    }),
    resumeViewsGetTags: build.query<
      ResumeViewsGetTagsApiResponse,
      ResumeViewsGetTagsApiArg
    >({
      query: () => ({ url: `/api/tags` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as resumeApi };
export type ResumeViewsResumePdfToTextApiResponse =
  /** status 200 OK */ UploadResumeOut;
export type ResumeViewsResumePdfToTextApiArg = {
  body: {
    file: Blob;
  };
};
export type ResumeViewsSaveJobApiResponse = /** status 200 OK */ SaveJobOut;
export type ResumeViewsSaveJobApiArg = {
  jobId: number;
};
export type ResumeViewsUnsaveJobApiResponse = /** status 200 OK */ SaveJobOut;
export type ResumeViewsUnsaveJobApiArg = {
  jobId: number;
};
export type ResumeViewsHideJobApiResponse = /** status 200 OK */ HideJobOut;
export type ResumeViewsHideJobApiArg = {
  jobId: number;
};
export type ResumeViewsUnhideJobApiResponse = /** status 200 OK */ HideJobOut;
export type ResumeViewsUnhideJobApiArg = {
  jobId: number;
};
export type ResumeViewsGetSavedJobsApiResponse = /** status 200 OK */ JobsOut;
export type ResumeViewsGetSavedJobsApiArg = {
  ascending?: boolean;
  page?: number;
  pageSize?: number;
};
export type ResumeViewsGetHiddenJobsApiResponse = /** status 200 OK */ JobsOut;
export type ResumeViewsGetHiddenJobsApiArg = {
  ascending?: boolean;
  page?: number;
  pageSize?: number;
};
export type ResumeViewsGetJobsApiResponse = /** status 200 OK */ JobsOut;
export type ResumeViewsGetJobsApiArg = {
  resumeUuid: string;
  month: string;
  year: number;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  tags?: string;
  saved?: boolean;
  hidden?: boolean;
};
export type ResumeViewsCreateResumeApiResponse =
  /** status 200 OK */ CreateResumeOut;
export type ResumeViewsCreateResumeApiArg = {
  createResumeIn: CreateResumeIn;
};
export type ResumeViewsGetResumeApiResponse = /** status 200 OK */ ResumeOut;
export type ResumeViewsGetResumeApiArg = {
  resumeUuid: string;
};
export type ResumeViewsGetTagsApiResponse = /** status 200 OK */ TagsOut;
export type ResumeViewsGetTagsApiArg = void;
export type UploadResumeOut = {
  text: string;
};
export type SaveJobOut = {
  saved: boolean;
};
export type HideJobOut = {
  hidden: boolean;
};
export type HnJobPostingSchemaOut = {
  id?: number;
  whos_hiring_post: number;
  hn_id: number;
  posted_by: string;
  time_posted: number;
  updated_at: string;
  display_text: string;
  tags: string[];
  saved: boolean;
  hidden: boolean;
};
export type JobsOut = {
  jobs: HnJobPostingSchemaOut[];
  total_jobs: number;
};
export type CreateResumeOut = {
  uuid: string;
};
export type CreateResumeIn = {
  text: string;
};
export type ResumeOut = {
  uuid: string;
  text: string;
};
export type TagsOut = {
  tags: {
    [key: string]: string[];
  };
};
