import React from 'react'
import { formatTag, tagToEmoji, getTagColor } from "../../utils/tagFormatter";

type GreenBadgeProps = {
    tag: string;
    key: number;
};


const GreenBadge: React.FC<GreenBadgeProps> = ({ tag, key }) => {

    return (
        <span key={key} className="inline-flex items-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded m-1 dark:bg-green-900 dark:text-green-300">
            {tagToEmoji(tag)} {formatTag(tag)}
        </span>
    )
}

export default GreenBadge

