import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../redux/hooks'
import { selectIsLoggedIn } from '../redux/userSlice'
import { Tooltip } from 'flowbite-react';
import { useResumeViewsHideJobMutation, useResumeViewsUnhideJobMutation } from "../store/enhancedResumeApi";

type HideJobButtonProps = {
    job_id: Number
    initialHideState: boolean,
}

const HideJobButton: React.FC<HideJobButtonProps> = ({
    job_id,
    initialHideState,
}) => {

    const [hidden, setHidden] = useState(initialHideState);

    useEffect(() => {
        setHidden(initialHideState);
    }, [initialHideState])

    const [
        hideJob,
        {
            isLoading: hideJobIsLoading,
            isError: hideJobIsError,
            error: hideJobError,
            isSuccess: hideJobIsSuccess,
            data: hideJobData,
        },
    ] = useResumeViewsHideJobMutation();

    const [
        unhideJob,
        {
            isLoading: unhideJobIsLoading,
            isError: unhideJobIsError,
            error: unhideJobError,
            isSuccess: unhideJobIsSuccess,
            data: unhideJobData,
        },
    ] = useResumeViewsUnhideJobMutation();

    function buttonPressHandler() {
        if (!isLoggedIn) return;
        if (hidden) {
            unhideJob({ jobId: Number(job_id) });
            setHidden(false);
        } else {
            hideJob({ jobId: Number(job_id) });
            setHidden(true);
        }
    }

    const isLoggedIn = useAppSelector(selectIsLoggedIn)

    const hideJobButtonContent = (
        <button
            type="button"
            className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-800 dark:hover:border-gray-800 dark:focus:ring-gray-600 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg flex items-center justify-center"
            onClick={() => { buttonPressHandler() }}
        >
            <span className="flex items-center rounded-md text-xs px-2 py-1">
                <svg className="w-4 h-4 mr-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                {hidden ? 'Unhide Job' : 'Hide Job'}
            </span>
        </button>
    )

    return isLoggedIn ? hideJobButtonContent : (
        <Tooltip
            content={`Please login to ${hidden ? 'unhide' : 'hide'} this job.`}
            style="light"
        >
            {hideJobButtonContent}
        </Tooltip>
    )
}

export default HideJobButton;
