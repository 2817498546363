import { createSlice } from '@reduxjs/toolkit'

interface UserState {
    isLoggedIn: boolean
}

const initialState: UserState = {
    isLoggedIn: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    }
})

export const selectIsLoggedIn = () => {
    const scriptElement = document.querySelector('#isLoggedIn');
    if (scriptElement) {
        const isLoggedIn = JSON.parse(scriptElement.textContent!);
        return isLoggedIn;
    }
    return false; // Default value if the script element is not found
    //               this should never happen though
};


export default userSlice.reducer
