import React from 'react'

import { getTagColor } from "../utils/tagFormatter";
import BlueBadge from './badges/BlueBadge';
import GreenBadge from './badges/GreenBadge';
import IndigoBadge from './badges/IndigoBadge';
import PinkBadge from './badges/PinkBadge';
import PurpleBadge from './badges/PurpleBadge';
import RedBadge from './badges/RedBadge';
import YellowBadge from './badges/YellowBadge';

type JobTagBadgesProps = {
    tags: string[];
};

const JobTagBadges: React.FC<JobTagBadgesProps> = ({ tags }) => {
    return (
        <div>
            {tags.map((tag, index) => {
                const color = getTagColor(tag);
                return (() => {
                    switch (color) {
                        case 'blue':
                            return <BlueBadge tag={tag} key={index} />
                        case 'green':
                            return <GreenBadge tag={tag} key={index} />
                        case 'indigo':
                            return <IndigoBadge tag={tag} key={index} />
                        case 'pink':
                            return <PinkBadge tag={tag} key={index} />
                        case 'purple':
                            return <PurpleBadge tag={tag} key={index} />
                        case 'red':
                            return <RedBadge tag={tag} key={index} />
                        case 'yellow':
                            return <YellowBadge tag={tag} key={index} />
                        default:
                            return <></>
                    }
                })()
            })}
        </div>
    )
}

export default JobTagBadges
