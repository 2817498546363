import { resumeApi as api } from './resumeApi'

export const enhancedResumeApi = api.enhanceEndpoints({
    addTagTypes: ['Jobs', 'SavedJobs', 'HiddenJobs'],
    endpoints: {
        resumeViewsGetJobs: {
            providesTags: ['Jobs', 'SavedJobs', 'HiddenJobs'],
        },
        resumeViewsHideJob: {
            invalidatesTags: ['Jobs']
        },
        resumeViewsUnhideJob: {
            invalidatesTags: ['Jobs']
        },
    }
})
export const {
    useResumeViewsResumePdfToTextMutation,
    useResumeViewsSaveJobMutation,
    useResumeViewsUnsaveJobMutation,
    useResumeViewsHideJobMutation,
    useResumeViewsUnhideJobMutation,
    useResumeViewsGetSavedJobsQuery,
    useResumeViewsGetHiddenJobsQuery,
    useResumeViewsGetJobsQuery,
    useResumeViewsCreateResumeMutation,
    useResumeViewsGetResumeQuery,
    useResumeViewsGetTagsQuery,
} = enhancedResumeApi;
