interface Dictionary {
    [key: string]: {
        [key: string]: string;
    };
}

export const categoryDictionary: { [key: string]: string; } = {
    'arrangement': 'Arrangement',
    'technology': 'Technology',
    'city': 'City',
    'country': 'Country',
    'role/engineering': 'Role (Engineering)',
    'role/business': 'Role (Business)',
    'role/design': 'Role (Design)',
    'job-type': 'Job Type',
};


const tagDictionary: { [key: string]: string; } = {
    'technology/cplusplus': 'C++',
    'technology/csharp': 'C#',
    'technology/ember': 'Ember.js',
    'technology/ios': 'iOS',
    'technology/javascript': 'JavaScript',
    'technology/node': 'Node.js',
    'technology/php': 'PHP',
    'technology/python-fastapi': 'Python FastAPI',
    'technology/rails': 'Ruby on Rails',
    'technology/vue': 'Vue.js',
    'technology/wasm': 'WebAssembly',
    'technology/react': 'React.js',
    'technology/typescript': 'TypeScript',
    'location/bogota': 'Bogotá',
    'location/cambridge-ma': 'Cambridge (MA)',
    'location/cambridge-uk': 'Cambridge (UK)',
    'location/dc': 'Washington DC',
    'location/la': 'Los Angeles',
    'location/melbourne-au': 'Melbourne (AU)',
    'location/melbourne-fl': 'Melbourne (FL)',
    'location/nyc': 'New York City',
    'location/phoenix-az': 'Phoenix (AZ)',
    'location/st-louis': 'St. Louis',
    'role/design/ux-designer': 'UX Designer',
    'role/design/ui-designer': 'UI Designer',
    'role/engineering/qa-engineer': 'QA Engineer',
    'role/business/business-operations': 'Business Ops & Strategy',
};

const emojiDictionary: { [key: string]: string; } = {
    'arrangement/remote': '🧑‍💻',
    'arrangement/in-person': '🏢',
    'arrangement/hybrid': '🏢🧑‍💻',

    'technology/android': '🤖',
    'technology/angular': '🅰️',
    'technology/c': '🔧',
    'technology/clojure': '🔮',
    'technology/cplusplus': '🔶',
    'technology/csharp': '🔷',
    'technology/dart': '🎯',
    'technology/elixir': '💧',
    'technology/elm': '🌳',
    'technology/ember': '🐹',
    'technology/flutter': '🦋',
    'technology/go': '🐹',
    'technology/haskell': 'λ',
    'technology/htmx': '🐴',
    'technology/ios': '🍏',
    'technology/java': '☕',
    'technology/javascript': '🌐',
    'technology/julia': '🔰',
    'technology/kotlin': '🧑‍💻',
    'technology/kubernetes': '☸️',
    'technology/lua': '🌕',
    'technology/node': '🟢',
    'technology/ocaml': '🐫',
    'technology/perl': '🐪',
    'technology/php': '🐘',
    'technology/python': '🐍',
    'technology/python-django': '🐍🎸',
    'technology/python-flask': '🐍🍺',
    'technology/python-fastapi': '🐍🚀',
    'technology/rails': '🚂',
    'technology/react': '⚛️',
    'technology/react-native': '⚛️📱',
    'technology/ruby': '💎',
    'technology/rust': '🦀',
    'technology/scala': '🚀',
    'technology/swift': '🐦',
    'technology/typescript': '🔤',
    'technology/vue': '👀',
    'technology/wasm': '📦',

    'country/argentina': '🇦🇷',
    'country/australia': '🇦🇺',
    'country/belgium': '🇧🇪',
    'country/brazil': '🇧🇷',
    'country/canada': '🇨🇦',
    'country/chile': '🇨🇱',
    'country/china': '🇨🇳',
    'country/colombia': '🇨🇴',
    'country/czechia': '🇨🇿',
    'country/france': '🇫🇷',
    'country/germany': '🇩🇪',
    'country/united-kingdom': '🇬🇧',
    'country/greece': '🇬🇷',
    'country/hungary': '🇭🇺',
    'country/india': '🇮🇳',
    'country/italy': '🇮🇹',
    'country/japan': '🇯🇵',
    'country/mexico': '🇲🇽',
    'country/netherlands': '🇳🇱',
    'country/nigeria': '🇳🇬',
    'country/pakistan': '🇵🇰',
    'country/peru': '🇵🇪',
    'country/philippines': '🇵🇭',
    'country/poland': '🇵🇱',
    'country/portugal': '🇵🇹',
    'country/romania': '🇷🇴',
    'country/russia': '🇷🇺',
    'country/saudi-arabia': '🇸🇦',
    'country/south-africa': '🇿🇦',
    'country/south-korea': '🇰🇷',
    'country/spain': '🇪🇸',
    'country/sri-lanka': '🇱🇰',
    'country/sweden': '🇸🇪',
    'country/taiwan': '🇹🇼',
    'country/thailand': '🇹🇭',
    'country/turkey': '🇹🇷',
    'country/ukraine': '🇺🇦',
    'country/united-states': '🇺🇸',

    'location/amsterdam': '🇳🇱',
    'location/ann-arbor': '🇺🇸',
    'location/antwerp': '🇧🇪',
    'location/atlanta': '🇺🇸',
    'location/auckland': '🇳🇿',
    'location/austin': '🇺🇸',
    'location/bangalore': '🇮🇳',
    'location/bangkok': '🇹🇭',
    'location/barcelona': '🇪🇸',
    'location/beijing': '🇨🇳',
    'location/berkeley': '🇺🇸',
    'location/berlin': '🇩🇪',
    'location/bogota': '🇨🇴',
    'location/boston': '🇺🇸',
    'location/boulder': '🇺🇸',
    'location/braga': '🇵🇹',
    'location/bratislava': '🇸🇰',
    'location/brooklyn': '🇺🇸',
    'location/brussels': '🇧🇪',
    'location/budapest': '🇭🇺',
    'location/burlingame': '🇺🇸',
    'location/cambridge-ma': '🇺🇸',
    'location/cambridge-uk': '🇬🇧',
    'location/cape-town': '🇿🇦',
    'location/charlotte': '🇺🇸',
    'location/chennai': '🇮🇳',
    'location/chicago': '🇺🇸',
    'location/cologne': '🇩🇪',
    'location/cupertino': '🇺🇸',
    'location/dallas': '🇺🇸',
    'location/dc': '🇺🇸',
    'location/denver': '🇺🇸',
    'location/detroit': '🇺🇸',
    'location/dubai': '🇦🇪',
    'location/dublin': '🇮🇪',
    'location/durham': '🇺🇸',
    'location/edinburgh': '🇬🇧',
    'location/eindhoven': '🇳🇱',
    'location/emeryville': '🇺🇸',
    'location/geneve': '🇨🇭',
    'location/ghent': '🇧🇪',
    'location/guadalajara': '🇲🇽',
    'location/hong-kong': '🇭🇰',
    'location/hangzhou': '🇨🇳',
    'location/houston': '🇺🇸',
    'location/hyderabad': '🇮🇳',
    'location/irvine': '🇺🇸',
    'location/lausanne': '🇨🇭',
    'location/kansas-city': '🇺🇸',
    'location/knoxville': '🇺🇸',
    'location/leuven': '🇧🇪',
    'location/lincoln': '🇺🇸',
    'location/lisbon': '🇵🇹',
    'location/la': '🇺🇸',
    'location/london': '🇬🇧',
    'location/los-gatos': '🇺🇸',
    'location/madrid': '🇪🇸',
    'location/manila': '🇵🇭',
    'location/melbourne-au': '🇦🇺',
    'location/melbourne-fl': '🇺🇸',
    'location/menlo-park': '🇺🇸',
    'location/mexico-city': '🇲🇽',
    'location/mountain-view': '🇺🇸',
    'location/montreal': '🇨🇦',
    'location/munich': '🇩🇪',
    'location/nyc': '🇺🇸',
    'location/oakland': '🇺🇸',
    'location/oslo': '🇳🇴',
    'location/oxford': '🇬🇧',
    'location/palo-alto': '🇺🇸',
    'location/paris': '🇫🇷',
    'location/phoenix-az': '🇺🇸',
    'location/philadelphia': '🇺🇸',
    'location/pittsburgh': '🇺🇸',
    'location/portland': '🇺🇸',
    'location/prague': '🇨🇿',
    'location/princeton': '🇺🇸',
    'location/pune': '🇮🇳',
    'location/raleigh': '🇺🇸',
    'location/redwood-city': '🇺🇸',
    'location/salt-lake-city': '🇺🇸',
    'location/san-antonio': '🇺🇸',
    'location/san-diego': '🇺🇸',
    'location/san-francisco': '🇺🇸',
    'location/san-jose': '🇺🇸',
    'location/san-mateo': '🇺🇸',
    'location/sao-paulo': '🇧🇷',
    'location/seattle': '🇺🇸',
    'location/seoul': '🇰🇷',
    'location/shanghai': '🇨🇳',
    'location/singapore': '🇸🇬',
    'location/st-louis': '🇺🇸',
    'location/stockholm': '🇸🇪',
    'location/sunnyvale': '🇺🇸',
    'location/sydney': '🇦🇺',
    'location/tel-aviv': '🇮🇱',
    'location/tokyo': '🇯🇵',
    'location/toronto': '🇨🇦',
    'location/vienna': '🇦🇹',
    'location/waterloo': '🇨🇦',
    'location/wellington': '🇳🇿',
    'location/zurich': '🇨🇭',

    'role/engineering/backend-engineer': '🔧',
    'role/engineering/frontend-engineer': '🎨',
    'role/engineering/fullstack-engineer': '🔧🎨',
    'role/engineering/devops-engineer': '🚀',
    'role/engineering/data-scientist': '📊',
    'role/engineering/data-engineer': '🛠️',
    'role/engineering/machine-learning-engineer': '🤖',
    'role/engineering/qa-engineer': '✅',

    'role/design/ux-designer': '🖌️',
    'role/design/ui-designer': '🎨',

    'role/business/product-manager': '📝',
    'role/business/project-manager': '🗂️',
    'role/business/business-operations': '📈',
    'role/business/business-development': '🤝',
    'role/business/sales': '💰',

    'job-type/full-time': '🗓️',
    'job-type/part-time': '⌛',
    'job-type/contract': '📝',
    'job-type/internship': '🎓'



};

export const tagToEmoji = (tag: string): string => {
    const emoji = emojiDictionary?.[tag] || '';
    return emoji;
};

function hashCode(string) {
    var hash = 0;
    for (var i = 0; i < string.length; i++) {
        var code = string.charCodeAt(i);
        hash = ((hash << 42) - hash) + code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export const getTagColor = (tag: string): string => {
    const colors = [
        'blue',
        'green',
        'yellow',
        'red',
        'purple',
        'pink',
        'indigo',
    ];
    const color = colors[Math.abs(hashCode(tag)) % colors.length];
    return color;
};


export const formatTag = (tag: string): string => {
    var tagArray = tag.split('/');
    var tagSlug = tagArray[tagArray.length - 1];

    const formattedTag = tagDictionary?.[tag] || '';
    if (formattedTag) {
        return formattedTag;
    }

    // If not found in the dictionary, then format it by removing dashes and capitalizing words
    return tagSlug
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
