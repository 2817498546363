import React from 'react'
import { formatTag, tagToEmoji, getTagColor } from "../../utils/tagFormatter";

type PurpleBadgeProps = {
    tag: string;
    key: number;
};


const PurpleBadge: React.FC<PurpleBadgeProps> = ({ tag, key }) => {

    return (
        <span key={key} className="inline-flex items-center bg-purple-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded m-1 dark:bg-purple-900 dark:text-purple-300">
            {tagToEmoji(tag)} {formatTag(tag)}
        </span>
    )
}

export default PurpleBadge

