
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

function getCsrfToken(): string {
    // Select the hidden input field by its name attribute
    const tokenElement: HTMLInputElement = document.querySelector('input[name="csrfmiddlewaretoken"]')!;
    // Return the value of the hidden input field
    return tokenElement.value;
}

export const emptySplitApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: (headers, { getState }) => {
            // Get the CSRF token
            const csrfToken = getCsrfToken();
            // If the token exists, set it in the headers
            if (csrfToken) {
                headers.set('X-CSRFToken', csrfToken);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});
