import React from 'react'
import { formatTag, tagToEmoji, getTagColor } from "../../utils/tagFormatter";

type RedBadgeProps = {
    tag: string;
    key: number;
};


const RedBadge: React.FC<RedBadgeProps> = ({ tag, key }) => {

    return (
        <span key={key} className="inline-flex items-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded m-1 dark:bg-red-900 dark:text-red-300">
            {tagToEmoji(tag)} {formatTag(tag)}
        </span>
    )
}

export default RedBadge

