import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../redux/hooks'
import { selectIsLoggedIn } from '../redux/userSlice'
import { Tooltip } from 'flowbite-react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useResumeViewsSaveJobMutation, useResumeViewsUnsaveJobMutation } from "../store/enhancedResumeApi";

type SaveJobButtonProps = {
    job_id: Number,
    initialSaveState: boolean,
}

const SaveJobButton: React.FC<SaveJobButtonProps> = ({
    job_id,
    initialSaveState,
}) => {

    const [saved, setSaved] = useState(initialSaveState);
    const [confetti, setConfetti] = useState(false);
    const isLoggedIn = useAppSelector(selectIsLoggedIn)

    useEffect(() => {
        setSaved(initialSaveState);
    }, [initialSaveState])

    const [
        saveJob,
        {
            isLoading: saveJobIsLoading,
            isError: saveJobIsError,
            error: saveJobError,
            isSuccess: saveJobIsSuccess,
            data: saveJobData
        },
    ] = useResumeViewsSaveJobMutation();

    const [
        unsaveJob,
        {
            isLoading: unsaveJobIsLoading,
            isError: unsaveJobIsError,
            error: unsaveJobError,
            isSuccess: unsaveJobIsSuccess,
            data: unsaveJobData,
        },
    ] = useResumeViewsUnsaveJobMutation();


    function buttonPressHandler() {
        if (!isLoggedIn) return;
        if (saved) {
            unsaveJob({ jobId: Number(job_id) });
            setSaved(false);
            setConfetti(false);
        } else {
            saveJob({ jobId: Number(job_id) });
            setSaved(true);
            setConfetti(true);
        }
    }

    const heartWithFill = <svg className="w-4 h-4 mr-2 text-red-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
        <path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z" />
    </svg>;

    const heartNoFill = <svg className="w-4 h-4 mr-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 19">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4C5.5-1.5-1.5 5.5 4 11l7 7 7-7c5.458-5.458-1.542-12.458-7-7Z" />
    </svg>;


    const icon = saved ? heartWithFill : heartNoFill;

    const saveJobButton = (
        <button
            type="button"
            className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-800 dark:hover:border-gray-800 dark:focus:ring-gray-600 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg flex items-center justify-center"
            onClick={() => buttonPressHandler()}
        >
            <span className="flex items-center rounded-md text-xs px-2 py-1">
                {icon}
                Save Job
                {confetti && <ConfettiExplosion force={0.3} duration={2200} particleCount={60} />}
            </span>
        </button>
    )

    return isLoggedIn ? saveJobButton : (
        <Tooltip
            content="Please login to save this job."
            style="light"
        >
            {saveJobButton}
        </Tooltip>
    )
}

export default SaveJobButton;
